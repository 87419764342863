// @flow
import React, { useContext } from 'react';

import { Switch, Route } from 'react-router-dom';

import { Dashboard } from 'pages/Dashboard';
import { ReturnOfInvestmentStatistics } from 'pages/ReturnOfInvestmentStatistics';
import { YearProfitStatistics } from 'pages/YearProfitStatistics';
import { RateOfProfitStatistics } from 'pages/RateOfProfitStatistics';
import { DuplicateCars } from 'pages/DuplicateCars';
import { WrongHistoryCars } from 'pages/WrongHistoryCars';
import { CarPrices } from 'pages/CarPrices';
import { Pricelist } from 'pages/Pricelist';

import { AuthContext } from '../../App';

export const Routes = () => {
  const { role } = useContext(AuthContext);

  const isAdmin = role === 'admin';

  return (
    <Switch>
      <Route path="/return-of-investment">
        <ReturnOfInvestmentStatistics />
      </Route>

      {isAdmin && (
        <Route path="/rate-of-profit-statistics">
          <RateOfProfitStatistics />
        </Route>
      )}

      {isAdmin && (
        <Route path="/year-profit-statistics">
          <YearProfitStatistics />
        </Route>
      )}

      {isAdmin && (
        <Route path="/duplicate-cars">
          <DuplicateCars />
        </Route>
      )}

      {isAdmin && (
        <Route path="/wrong-history">
          <WrongHistoryCars />
        </Route>
      )}

      {isAdmin && (
        <Route path="/car-prices">
          <CarPrices />
        </Route>
      )}

      {isAdmin && (
        <Route path="/pricelist">
          <Pricelist />
        </Route>
      )}

      <Route path="/">
        <Dashboard />
      </Route>
    </Switch>
  );
};
