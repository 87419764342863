import gql from 'graphql-tag';

export const RETURN_OF_INVESTMENT_CAR_INFO_FRAGMENT = gql`
  fragment ReturnOnInvestmentCarInfoFragment on Car {
    id
    status
    numbers
    type {
      id
      amortization
    }
    fullName
    extraEquipment
    engine
    enginePower
    engineVolume
    vin
    color
    weight
    registrationDate
    pricelistPrice
    price
    salePrice
    leasingCompany
    activeMonthsTotal
    ownedMonthsTotal
    realAmortization
  }
`;

export const RETURN_OF_INVESTMENT_CAR_RETURNS_FRAGMENT = gql`
  fragment ReturnOnInvestmentCarReturnsFragment on Car {
    id
    numbers
    sales
    price
    salePrice
    activeMonthsTotal
    costs {
      id
      motorInsurance
      insurance
      roadTax
      interest
    }
    type {
      id
      tirePrice
    }
    additionalCosts {
      id
      title
      value
    }
  }
`;

export const RETURN_OF_INVESTMENT_INTEREST_DIALOG_FRAGMENT = gql`
  fragment ReturnOnInvestmentInterestDialogFragment on Car {
    id
    numbers
    dateOfRentingStart
    activeMonthsForInterest: activeMonths(untilDate: $untilDate) {
      id
      month
      year
      costs {
        id
        interest
      }
    }
  }
`;

export const RETURN_OF_INVESTMENT_CAR_HISTORY_FRAGMENT = gql`
  fragment ReturnOnInvestmentCarHistoryFragment on HistoryEvent {
    id
    name
    date
    description
  }
`;
