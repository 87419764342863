// @flow
import React, { useMemo } from 'react';

import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';

export type Props = {} & TextField;

export const HistoryEventNames = ({ ...passingProps }: Props) => {
  const options = useMemo(
    () => [
      {
        label: 'Začatie prenajímania',
        value: 'START_OF_RENTING'
      },
      {
        label: 'Ukončenie prenajímania',
        value: 'END_OF_RENTING'
      },
      {
        label: 'Ukončenie KASKO',
        value: 'TERMINATE_INSURANCE'
      },
      {
        label: 'Vyradenie z evidencie',
        value: 'REMOVE_FROM_REGISTER'
      },
      {
        label: 'Predaj',
        value: 'SALE'
      }
    ],
    []
  );

  return (
    <Field component={TextField} select options={options} {...passingProps}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};
