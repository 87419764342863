// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

let bugsnagClient;
if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  bugsnagClient = Bugsnag.start(
    Object.assign(
      {
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
        appType: 'client',
        appVersion: undefined,
        plugins: [new BugsnagPluginReact()]
      },
      process.env.REACT_APP_BUILD_NUMBER ? { appVersion: process.env.REACT_APP_BUILD_NUMBER } : {}
    )
  );
}
const ErrorBoundary = bugsnagClient
  ? Bugsnag.getPlugin('react').createErrorBoundary(React)
  : React.Fragment;
global.bugsnagClient = bugsnagClient || {
  notify: (e) => {
    console.log(e);
  }
};

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  // $FlowFixMe
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
