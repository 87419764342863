// @flow
import React, { useContext } from 'react';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

// icons
import FolderIcon from '@material-ui/icons/Folder';
import CarIcon from '@material-ui/icons/DirectionsCar';
import TableIcon from '@material-ui/icons/TableChart';
import CalendarIcon from '@material-ui/icons/Today';
import FilesIcon from '@material-ui/icons/FileCopy';
import HistoryIcon from '@material-ui/icons/History';
import EuroIcon from '@material-ui/icons/Euro';
// import SettingsIcon from '@material-ui/icons/Settings';

import { MainMenuLink } from './MainMenuLink';
import { AuthContext } from '../../App';

const basicList = [
  {
    primaryText: 'Úvod',
    icon: <FolderIcon />,
    to: '/'
  },
  {
    primaryText: 'Návratnosť vozidiel',
    icon: <CarIcon />,
    to: '/return-of-investment'
  }
];

const adminList = [
  {
    primaryText: 'Mesačná výnosnosť',
    icon: <TableIcon />,
    to: '/rate-of-profit-statistics'
  },
  {
    primaryText: 'Ročná štatistika',
    icon: <CalendarIcon />,
    to: '/year-profit-statistics'
  },
  {
    primaryText: 'Duplicitné autá',
    icon: <FilesIcon />,
    to: '/duplicate-cars'
  },
  {
    primaryText: 'Zlá história',
    icon: <HistoryIcon />,
    to: '/wrong-history'
  },
  {
    primaryText: 'Ceny vozidiel',
    icon: <EuroIcon />,
    to: '/car-prices'
  },
  {
    primaryText: 'Cenník',
    icon: <EuroIcon />,
    to: '/pricelist'
  }
];

export type Props = {|
  /**
   * Callback to run on item click
   */
  onItemClick?: () => void
|};

export const MainMenu = ({ onItemClick }: Props) => {
  const { role } = useContext(AuthContext);

  const list = role === 'admin' ? [...basicList, ...adminList] : basicList;

  return (
    <List>
      {list.map(({ primaryText, icon, to }) => (
        <MainMenuLink
          key={primaryText}
          primaryText={primaryText}
          icon={icon}
          to={to}
          onClick={onItemClick}
        />
      ))}

      <Divider style={{ margin: '12px 0' }} />

      {/* <MainMenuLink
        primaryText="Settings & account"
        icon={<Settings />}
        to="/settings"
        onClick={onItemClick}
      /> */}
    </List>
  );
};
