// @flow
import React from 'react';

import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';

import { HistoryEventNames } from 'components/Fields/HistoryEventNames';

export type Props = {};

export const HistoryEventForm = (/* {}: Props */) => {
  return (
    <>
      <HistoryEventNames label="Názov udalosti" name="name" variant="outlined" fullWidth />

      <Field component={DatePicker} name="date" label="Dátum" inputVariant="outlined" fullWidth />

      <Field
        component={TextField}
        name="description"
        label="Popis"
        variant="outlined"
        multiline
        format="D.M.YYYY"
        rows={2}
        fullWidth
      />
    </>
  );
};
