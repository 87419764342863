// @flow
import React, { createContext, useState, useEffect } from 'react';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  withStyles
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

import { ApolloProvider } from '@apollo/client';
import { client, authorizationToken } from 'config/apollo';

import { Layout } from 'layouts/Layout';

const admins = [
  'martin.vysnovsky@myketler.com',
  'karolsloboda100@gmail.com',
  'jozef.hikl22@gmail.com',
  'sloboda@edencars.sk',
  'martinvysnovsky@gmail.com'
];

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
};
firebase.initializeApp(config);

export const AuthContext = createContext<{|
  user: null | { displayName: string },
  logout: () => void
|}>({
  user: null,
  logout: () => {
    // do nothing
  }
});

const GlobalCss = withStyles({
  '@global': {
    'form [class*="MuiFormControl-root"]': {
      marginBottom: '16px'
    }
  }
})(() => null);

function App() {
  const [user, setUser] = useState(null);

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google as auth provider
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) =>
        user &&
        user.getIdToken().then((token) => {
          authorizationToken(token);
          setUser(user);
        })
    );

    return () => unregisterAuthObserver();
  }, []);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      responsiveFontSizes(
        createTheme({
          palette: {
            type: prefersDarkMode ? 'dark' : 'light'
          }
        })
      ),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={{ ...theme }}>
      <CssBaseline />
      <GlobalCss />

      {!authorizationToken() ? (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      ) : (
        <AuthContext.Provider
          value={{
            user,
            role: admins.includes(user.email) ? 'admin' : 'sales',
            logout: () => {
              firebase.auth().signOut();
              authorizationToken(null);
              setUser(null);
            }
          }}
        >
          {user ? (
            <ApolloProvider client={client}>
              <Layout />
            </ApolloProvider>
          ) : null}
        </AuthContext.Provider>
      )}
    </ThemeProvider>
  );
}

export default App;
