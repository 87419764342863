// @flow
import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { GET_PRICELIST } from 'api/queries';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { Loading } from 'components/Loading';
import { PricelistTypesField } from 'components/Fields/PricelistTypesField';
import { useQueryString } from 'components/useQueryString';

import { PricelistTable } from './components/PricelistTable';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  table: {
    marginBottom: theme.spacing(3)
  }
}));

export const Pricelist = () => {
  const css = useStyles();

  const [type, setType] = useQueryString('type', 'road_tax');

  const { loading, error, data } = useQuery(GET_PRICELIST, {
    variables: {
      type: type.toUpperCase()
    }
  });

  const handleTypeChange = useCallback(
    (event) => {
      setType(event.target.value);
    },
    [setType]
  );

  const tables = useMemo(() => {
    const tables = {};

    if (data && data.pricelist) {
      data.pricelist.forEach(({ type, variant, validFrom, validTo, prices }) => {
        if (!tables[variant]) {
          tables[variant] = [];
        }

        tables[variant].push({
          type,
          validFrom,
          validTo,
          prices
        });
      });
    }

    return tables;
  }, [data]);

  if (error) {
    global.bugsnagClient.notify(error);
  }

  return (
    <>
      <div className={css.header}>
        <PricelistTypesField value={type} onChange={handleTypeChange} />
      </div>

      <Divider className={css.divider} />

      {loading ? (
        <Loading />
      ) : (
        Object.keys(tables).map((variant) => (
          <PricelistTable
            key={variant}
            variant={variant}
            data={tables[variant]}
            className={css.table}
          />
        ))
      )}
    </>
  );
};
