// @flow
import React from 'react';
import classnames from 'classnames';

import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

export type Props = {} & TextField;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '0 !important',
    '& input': {
      textAlign: 'right'
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      marginLeft: theme.spacing()
    }
  }
}));

export const PriceInput = ({ className, ...passingProps }: Props) => {
  const classes = useStyles();
  const { InputProps, ...moreProps } = passingProps;

  return (
    <Field
      component={TextField}
      fullWidth
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">&euro;</InputAdornment>,
        inputProps: {
          lang: 'sk',
          step: '0.01'
        },
        ...InputProps
      }}
      className={classnames(classes.root, className)}
      {...moreProps}
    />
  );
};
