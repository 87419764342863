// @flow
import React, { useMemo } from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export type Props = {} & TextField;

export const PricelistTypesField = ({ ...passingProps }: Props) => {
  const options = useMemo(
    () => [
      {
        label: 'Daň z motorových vozidiel',
        value: 'road_tax'
      },
      {
        label: 'PZP',
        value: 'motor_insurance'
      },
      {
        label: 'KASKO',
        value: 'accident_insurance'
      }
    ],
    []
  );

  return (
    <TextField select variant="outlined" {...passingProps}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
