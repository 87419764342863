// @flow
import React from 'react';

import Chip from '@material-ui/core/Chip';

export type Props = {
  type: string,
  className: string
};

export const VehicleTypeBadge = ({ type, className }: Props) => {
  let label = '';
  let color = 'primary';

  switch (type) {
    case 'electro':
      label = 'Elektro';
      color = 'success';
      break;
    case 'utility':
      label = 'Úžitkové';
      color = 'primary';
      break;
    default:
      return null;
  }

  return <Chip label={label} size="small" color={color} className={className} />;
};
