// @flow
import React, { useCallback } from 'react';

import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';

import { useMutation } from '@apollo/client';
import { PUT_CAR_ADDITIONAL_COST } from 'api/mutations';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { PriceInput } from 'components/PriceInput';

import type { ReturnOnInvestmentCarReturnsFragment } from 'api/__generated__/ReturnOnInvestmentCarReturnsFragment';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 500
  }
}));

export type Props = {
  /**
   * Car id
   */
  car: ReturnOnInvestmentCarReturnsFragment,
  /**
   * Whether to open dialog
   */
  open: boolean,
  /**
   * Callback to run on close request
   */
  onClose: () => void
};

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  value: yup.number().required().default(0)
});

export const AdditionalCostDialog = ({ car, open, onClose }: Props) => {
  const classes = useStyles();

  const [putCost] = useMutation(PUT_CAR_ADDITIONAL_COST);

  const handleSubmit = useCallback(
    ({ title, value }, { setSubmitting }) => {
      putCost({
        variables: {
          carId: car.id,
          title,
          value: value || 0
        }
      })
        .then(() => {
          onClose();
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [car.id, onClose, putCost]
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <Formik
        initialValues={validationSchema.cast({})}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, errors }) => (
          <Form className={classes.form}>
            <DialogTitle id="form-dialog-title">
              Dodatočné náklady pre vozidlo{' '}
              <Chip
                label={car.numbers[car.numbers.length - 1]}
                color="primary"
                variant="outlined"
                size="small"
              />
            </DialogTitle>

            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Field
                    component={TextField}
                    fullWidth
                    autoFocus
                    name="title"
                    label="Názov"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={4}>
                  <PriceInput name="value" label="Hodnota" variant="outlined" />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose}>Zrušiť</Button>

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={20} color="inherit" /> : 'Uložiť'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
