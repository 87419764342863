// @flow
import React, { useMemo } from 'react';

import { Table } from 'components/Table';
import { DateString } from 'components/DateString';

export const PricelistTable = ({ variant, data, className }) => {
  const priceColumns = useMemo(() => {
    const items = data || [];
    const columns = {};

    items.forEach(
      (item) =>
        item.prices &&
        item.prices.forEach(({ id, valueFrom, valueTo }) => {
          const title = `${valueFrom} -> ${valueTo}`;

          if (!columns[title]) {
            columns[title] = {
              title,
              field: `${Math.round(valueFrom * 100)}:${Math.round(valueTo * 100)}`,
              type:
                data[0].type === 'ROAD_TAX' || data[0].type === 'MOTOR_INSURANCE'
                  ? 'currency'
                  : 'numeric'
            };
          }
        })
    );

    return Object.values(columns).sort((a, b) => a.title.localeCompare(b.title));
  }, [data]);

  const columns = useMemo(
    () => [
      {
        title: 'Platné od',
        field: 'validFrom',
        render: ({ validFrom }) => <DateString value={validFrom} />
      },
      {
        title: 'Platné do',
        field: 'validTo',
        render: ({ validTo }) => (validTo ? <DateString value={validTo} /> : '-')
      },
      ...priceColumns
    ],
    [priceColumns]
  );

  const tableData = useMemo(() => {
    return data.map(({ validFrom, validTo, prices }) => ({
      validFrom,
      validTo,
      ...prices.reduce(
        (o, i) => ({
          ...o,
          [`${Math.round(i.valueFrom * 100)}:${Math.round(i.valueTo * 100)}`]: i.price
        }),
        {}
      )
    }));
  }, [data]);

  const options = useMemo(
    () => ({
      paging: false,
      filtering: false,
      exportButton: false
    }),
    []
  );

  return (
    <Table
      title={variant}
      columns={columns}
      data={tableData}
      options={options}
      className={className}
    />
  );
};
