// @flow
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { GET_DUPLICATE_CARS } from 'api/queries';

import MuiLink from '@material-ui/core/Link';

import { Table } from 'components/Table';

export const DuplicateCars = () => {
  const { loading, error, data } = useQuery(GET_DUPLICATE_CARS);

  const tableData = useMemo(() => {
    return (data && data.duplicateCars.map((item) => ({ ...item }))) || [];
  }, [data]);

  return (
    <Table
      isLoading={loading}
      error={error}
      columns={[
        {
          title: 'ŠPZ',
          field: 'number',
          cellStyle: {
            fontWeight: 'bold'
          },
          render: (children) =>
            children.number ? (
              <MuiLink component={Link} to={`/return-of-investment?car=${children.number}`}>
                {children.number}
              </MuiLink>
            ) : (
              children
            )
        }
      ]}
      data={tableData}
      title="Duplicitné autá"
      options={{
        paging: false,
        filtering: false,
        exportButton: false,
        search: false
      }}
    />
  );
};
