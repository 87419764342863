// @flow
import React, { useMemo, useCallback } from 'react';
import moment from 'moment';

import { Formik, Form, FieldArray } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';

import { useMutation } from '@apollo/client';
import { UPDATE_CAR_INTEREST_VALUE_MASS } from 'api/mutations';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { PriceInput } from 'components/PriceInput';

import type { ReturnOnInvestmentInterestDialogFragment } from 'api/__generated__/ReturnOnInvestmentInterestDialogFragment';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 500
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

export type Props = {
  /**
   * Car id
   */
  car: ReturnOnInvestmentInterestDialogFragment,
  /**
   * Whether to open dialog
   */
  open: boolean,
  /**
   * Callback to run on close request
   */
  onClose: () => void
};

const validationSchema = yup.object().shape({
  interests: yup
    .array()
    .of(
      yup.object().shape({
        year: yup.number().required(),
        month: yup.number().required(),
        value: yup.number().required().default(0)
      })
    )
    .required()
});

export const InterestEditDialog = ({ car, open, onClose }: Props) => {
  const classes = useStyles();

  const [updateInterests] = useMutation(UPDATE_CAR_INTEREST_VALUE_MASS);

  const handleSubmit = useCallback(
    ({ interests }, { setSubmitting }) => {
      updateInterests({
        variables: {
          id: car.id,
          data: interests.map((item) => ({ ...item, value: item.value || 0 })),
          untilDate: moment().startOf('month').add(70, 'months').toDate()
        }
      })
        .then(() => {
          onClose();
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [car.id, onClose, updateInterests]
  );

  const initialValues = useMemo(() => {
    const monthYearDate = moment(car.dateOfRentingStart).startOf('month').subtract(1, 'month');

    const data = car.activeMonthsForInterest.reduce(
      (o, { year, month, costs }) => ({
        ...o,
        [`${month}/${year}`]: (costs && costs.interest) || 0
      }),
      {}
    );

    return new Array(70).fill(1).map((_, index) => {
      monthYearDate.add(1, 'month');

      const month = monthYearDate.month() + 1;
      const year = monthYearDate.year();

      return {
        year,
        month,
        value: data[`${month}/${year}`] || 0
      };
    });
  }, [car]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <Formik
        enableReinitialize
        initialValues={validationSchema.cast({ interests: initialValues })}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, errors }) => (
          <Form className={classes.form}>
            <DialogTitle id="form-dialog-title">
              Úroky pre vozidlo{' '}
              <Chip
                label={car.numbers[car.numbers.length - 1]}
                color="primary"
                variant="outlined"
                size="small"
              />
            </DialogTitle>

            <DialogContent dividers className={classes.content}>
              <Table size="small">
                <TableBody>
                  <FieldArray
                    name="friends"
                    render={(arrayHelpers) =>
                      initialValues.map(({ year, month }, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row" align="right">
                              {`${month}/${year}`}
                            </TableCell>
                            <TableCell>
                              <PriceInput
                                name={`interests[${index}].value`}
                                autoFocus={index === 0}
                                variant="outlined"
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  />
                </TableBody>
              </Table>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose}>Zrušiť</Button>

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={20} color="inherit" /> : 'Uložiť'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
