// @flow
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export type Props = {
  children: string
};

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const ErrorMessage = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <Typography variant="body2" color="error" className={classes.root} gutterBottom>
      {children}
    </Typography>
  );
};
