// @flow
import React, { memo, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { UPDATE_CAR } from 'api/mutations';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

import { DateString } from 'components/DateString';
import { PricePopup } from 'components/PricePopup';
import { Price } from 'components/Price';
import { Percent } from 'components/Percent';
import { Weight } from 'components/Weight';
import { NumberString } from 'components/NumberString';
import { EditableCell } from 'components/EditableCell';

import type { ReturnOnInvestmentCarInfoFragment } from 'api/__generated__/ReturnOnInvestmentCarInfoFragment';

export type Props = {
  car: ReturnOnInvestmentCarInfoFragment
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1)
  }
}));

export const CarInfo = memo(({ car }: Props) => {
  const classes = useStyles();

  const [updateCar] = useMutation(UPDATE_CAR);

  const handleCarValueUpdateButRemoveVat = useCallback(
    ({ valueName, value }) => {
      return updateCar({
        variables: {
          id: car.id,
          data: {
            [valueName]: value / 1.2
          }
        }
      });
    },
    [car.id, updateCar]
  );

  if (!car) {
    return null;
  }

  const expectedAmortization =
    ((car.type && car.type.amortization) || 0) * (car.ownedMonthsTotal || 1);
  const realAmortization = (car.realAmortization || 0) * (car.activeMonthsTotal || 1);

  return (
    <Table aria-label="simple table">
      <TableBody>
        <TableRow key="fullName">
          <TableCell component="th" scope="row" variant="head">
            Značka
          </TableCell>
          <TableCell align="right">{car.fullName}</TableCell>
        </TableRow>

        <TableRow key="numbers">
          <TableCell component="th" scope="row" variant="head">
            Evidenčné čísla
          </TableCell>
          <TableCell align="right">
            {car.numbers &&
              car.numbers.map((number, key, arr) => (
                <Chip
                  key={number}
                  label={number}
                  color={key === arr.length - 1 ? 'primary' : 'default'}
                  variant="outlined"
                  size="small"
                  className={classes.chip}
                />
              ))}
          </TableCell>
        </TableRow>

        <TableRow key="vin">
          <TableCell component="th" scope="row" variant="head">
            VIN
          </TableCell>
          <TableCell align="right">
            <Chip label={car.vin} variant="outlined" size="small" />
          </TableCell>
        </TableRow>

        <TableRow key="engine">
          <TableCell component="th" scope="row" variant="head">
            Motorizácia
          </TableCell>
          <TableCell align="right">{car.engine}</TableCell>
        </TableRow>

        <TableRow key="extraEquipment">
          <TableCell component="th" scope="row" variant="head">
            Výbava
          </TableCell>
          <TableCell align="right">{car.extraEquipment}</TableCell>
        </TableRow>

        <TableRow key="color">
          <TableCell component="th" scope="row" variant="head">
            Farba
          </TableCell>
          <TableCell align="right">{car.color}</TableCell>
        </TableRow>

        {car.weight && (
          <TableRow key="weight">
            <TableCell component="th" scope="row" variant="head">
              Hmotnosť
            </TableCell>
            <TableCell align="right">
              <Weight value={car.weight} />
            </TableCell>
          </TableRow>
        )}

        <TableRow key="leasingCompany">
          <TableCell component="th" scope="row" variant="head">
            Lízingovka
          </TableCell>
          <TableCell align="right">{car.leasingCompany}</TableCell>
        </TableRow>

        <TableRow key="registrationDate">
          <TableCell component="th" scope="row" variant="head">
            Dátum registrácie
          </TableCell>
          <TableCell align="right">
            {car.registrationDate ? <DateString value={car.registrationDate} /> : null}
          </TableCell>
        </TableRow>

        <TableRow key="pricelistPrice">
          <TableCell component="th" scope="row" variant="head">
            Cenníková cena{' '}
            <Typography variant="caption" color="textSecondary">
              (s DPH)
            </Typography>
          </TableCell>
          <EditableCell
            align="right"
            value={(car.pricelistPrice && car.pricelistPrice * 1.2) || 0}
            onChange={handleCarValueUpdateButRemoveVat}
            textFieldProps={{
              InputProps: {
                endAdornment: <InputAdornment position="end">&euro;</InputAdornment>
              }
            }}
            data={{ valueName: 'pricelistPrice' }}
          >
            <PricePopup value={car.pricelistPrice || 0}>
              <Price addVat value={car.pricelistPrice || 0} />
            </PricePopup>
          </EditableCell>
        </TableRow>

        <TableRow key="price">
          <TableCell component="th" scope="row" variant="head">
            Obstarávacia cena{' '}
            <Typography variant="caption" color="textSecondary">
              (s DPH)
            </Typography>
          </TableCell>
          <EditableCell
            align="right"
            value={(car.price && car.price * 1.2) || 0}
            onChange={handleCarValueUpdateButRemoveVat}
            textFieldProps={{
              InputProps: {
                endAdornment: <InputAdornment position="end">&euro;</InputAdornment>
              }
            }}
            data={{ valueName: 'price' }}
          >
            <PricePopup
              value={car.price || 0}
              additionalDetails={[
                {
                  label: 'Zľava oproti cenníkovej cene',
                  value: (
                    <>
                      -
                      <Percent
                        value={1 - (car.pricelistPrice ? (car.price || 0) / car.pricelistPrice : 0)}
                      />
                    </>
                  )
                }
              ]}
            >
              <Price addVat value={car.price || 0} />
            </PricePopup>
          </EditableCell>
        </TableRow>

        <TableRow key="salePrice">
          <TableCell component="th" scope="row" variant="head">
            Predajná cena{' '}
            <Typography variant="caption" color="textSecondary">
              (s DPH)
            </Typography>
          </TableCell>
          <EditableCell
            align="right"
            value={(car.salePrice && car.salePrice * 1.2) || 0}
            onChange={handleCarValueUpdateButRemoveVat}
            textFieldProps={{
              InputProps: {
                endAdornment: <InputAdornment position="end">&euro;</InputAdornment>
              }
            }}
            data={{ valueName: 'salePrice' }}
          >
            <PricePopup
              value={car.salePrice || 0}
              additionalDetails={[
                {
                  label: 'Pokles oproti cenníkovej cene',
                  value: (
                    <>
                      -
                      <Percent
                        value={
                          1 - (car.pricelistPrice ? (car.salePrice || 0) / car.pricelistPrice : 0)
                        }
                      />
                    </>
                  )
                }
              ]}
            >
              <Price addVat value={car.salePrice || 0} />
            </PricePopup>
          </EditableCell>
        </TableRow>

        <TableRow key="activeMonthsTotal">
          <TableCell component="th" scope="row" variant="head">
            Počet mesiacov v prenájme / celkom
          </TableCell>
          <TableCell align="right">
            <NumberString value={car.activeMonthsTotal} /> /{' '}
            <NumberString value={car.ownedMonthsTotal} />
          </TableCell>
        </TableRow>

        <TableRow key="amortization">
          <TableCell component="th" scope="row" variant="head">
            Predpokladaná celková amortizácia
          </TableCell>
          <TableCell align="right">
            <PricePopup
              additionalDetails={[
                {
                  label: 'Predpokladaná celková amortizácia',
                  value: (
                    <Price
                      value={
                        ((car.type && car.type.amortization) || 0) * (car.ownedMonthsTotal || 1)
                      }
                      isCost={true}
                    />
                  )
                },
                {
                  label: 'Predpokladaná mesačná amortizácia',
                  value: <Price value={(car.type && car.type.amortization) || 0} isCost={true} />
                },
                {
                  label: 'Počet mesiacov',
                  value: <>{<NumberString value={car.ownedMonthsTotal} />}&nbsp;mes</>
                }
              ]}
            >
              <Price value={expectedAmortization} isCost={true} />
            </PricePopup>
          </TableCell>
        </TableRow>

        <TableRow key="realAmortization">
          <TableCell component="th" scope="row" variant="head">
            Skutočná celková amortizácia
          </TableCell>
          <TableCell align="right">
            <PricePopup
              additionalDetails={[
                {
                  label: 'Skutočná celková amortizácia',
                  value: <Price value={realAmortization} isCost={true} />
                },
                {
                  label: 'Skutočná mesačná amortizácia',
                  value: <Price value={car.realAmortization || 0} isCost={true} />
                },
                {
                  label: 'Počet mesiacov',
                  value: <>{<NumberString value={car.activeMonthsTotal} />}&nbsp;mes</>
                }
              ]}
            >
              <Price value={realAmortization} isCost={true} />
            </PricePopup>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
});
