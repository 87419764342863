// @flow
import React, { useState, useCallback } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import { GET_RETURN_OF_INVESTMENT_STATISTICS } from 'api/queries';

import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import HistoryIcon from '@material-ui/icons/HistoryOutlined';

import { Loading } from 'components/Loading';
import { CarsField } from 'components/Fields/CarsField';
import { useQueryString } from 'components/useQueryString';

import { CarInfo } from './Partials/CarInfo';
import { CarReturns } from './Partials/CarReturns';
import { CarHistory } from './Partials/CarHistory';
import { ActiveMonthsTable } from './Partials/ActiveMonthsTable';
import { ContractsTable } from './Partials/ContractsTable';
import { InvoicesTable } from './Partials/InvoicesTable';
import { CarNotes } from './Partials/CarNotes';
import { ChangelogDialog } from './Partials/ChangelogDialog';
import { VehicleTypeBadge } from './Partials/VehicleTypeBadge';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3)
  },
  vehicleTypeBadge: {
    marginLeft: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  chip: {
    marginLeft: theme.spacing(1)
  },
  activeStatus: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.success.main
  },
  historyTable: {
    marginTop: theme.spacing(2)
  },
  notes: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 1)
  },
  historyButton: {
    marginLeft: theme.spacing(1)
  }
}));

export const ReturnOfInvestmentStatistics = () => {
  const classes = useStyles();

  const [changelogDialogOpen, setChangelogDialogOpen] = useState(false);

  const [car, setCar] = useQueryString('car', '');

  const { loading, error, data } = useQuery(GET_RETURN_OF_INVESTMENT_STATISTICS, {
    variables: {
      carNumber: car,
      untilDate: moment().startOf('month').add(70, 'months').toDate()
    },
    skip: !car
  });

  const handleChangelogDialogOpen = useCallback(() => {
    if (car) {
      setChangelogDialogOpen(true);
    }
  }, [car]);

  const handleChangelogDialogClose = useCallback(() => {
    setChangelogDialogOpen(false);
  }, []);

  if (error) {
    global.bugsnagClient.notify(error);
    return null;
  }

  const carData = (data && data.car) || null;

  return (
    <>
      <div className={classes.header}>
        <CarsField value={car} onChange={setCar} />

        {carData && (
          <div className={classes.title}>
            <Typography variant="h5" component="h1">
              {carData.fullName}
            </Typography>

            <VehicleTypeBadge
              type={carData.vehicleType.toLowerCase()}
              className={classes.vehicleTypeBadge}
            />
          </div>
        )}

        <div>
          {carData && carData.status ? (
            <>
              <Chip
                label={carData.status}
                className={carData.status === 'Aktívne' ? classes.activeStatus : undefined}
              />

              <IconButton className={classes.historyButton} onClick={handleChangelogDialogOpen}>
                <HistoryIcon />
              </IconButton>
            </>
          ) : null}
        </div>
      </div>

      <Divider className={classes.divider} />

      {loading ? (
        <Loading />
      ) : !car ? (
        <Typography variant="subtitle1" color="textSecondary">
          Vyber vozidlo zo zoznamu
        </Typography>
      ) : !carData ? (
        <Typography variant="subtitle1" color="error">
          Vozidlo sa nenašlo
        </Typography>
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} xl={3}>
              <TableContainer component={Paper}>
                <CarInfo car={carData} />
              </TableContainer>

              <TableContainer component={Paper} className={classes.historyTable}>
                <CarHistory carId={carData.id} history={carData.history} />
              </TableContainer>
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <TableContainer component={Paper}>
                <CarReturns car={carData} />
              </TableContainer>

              <TableContainer component={Paper} className={classes.notes}>
                <CarNotes car={carData} />
              </TableContainer>
            </Grid>

            <Grid item xs={12} md={12} xl={6}>
              <ActiveMonthsTable carId={carData.id} />
            </Grid>
          </Grid>

          <ContractsTable carId={carData.id} />

          <InvoicesTable carId={carData.id} />

          <ChangelogDialog
            carId={carData.id}
            open={changelogDialogOpen}
            onClose={handleChangelogDialogClose}
          />
        </>
      )}
    </>
  );
};
