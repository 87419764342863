// @flow
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export type Props = {} & CircularProgress;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  }
}));

export const Loading = ({ ...passingProps }: Props) => {
  const classes = useStyles();

  return <CircularProgress size={24} className={classes.root} {...passingProps} />;
};
