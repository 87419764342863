// @flow
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { MTableCell } from 'material-table';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import UILink from '@material-ui/core/Link';

import { useQuery } from '@apollo/client';

import { GET_RATE_OF_PROFIT_STATISTICS } from 'api/queries';

import { Table, formatData, exportCsvFile } from 'components/Table';
import { MonthPicker } from 'components/MonthPicker';
import { useQueryString } from 'components/useQueryString';
import { DateString } from 'components/DateString';
import { Price } from 'components/Price';

const useStyles = makeStyles((theme) => ({
  totalsRow: {
    backgroundColor: theme.palette.action.selected
  }
}));

export const RateOfProfitStatistics = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [month, setMonth] = useQueryString('month', moment().month() + 1);
  const [year, setYear] = useQueryString('year', moment().year());

  const { loading, error, data } = useQuery(GET_RATE_OF_PROFIT_STATISTICS, {
    variables: {
      year: parseInt(year),
      month: parseInt(month)
    }
  });

  const handleMonthChange = useCallback(
    (value) => {
      setMonth(value.month() + 1);
      setYear(value.year());
    },
    [setYear, setMonth]
  );

  const totals = new Array(11).fill(0);

  const formatedData = useMemo(
    () =>
      data && data.activeCarsInMonth
        ? data.activeCarsInMonth.map((item) => {
            const amortization = (item.type && item.type.amortization) || 0;
            const cashflow =
              item.sales -
              (amortization +
                item.costs.motorInsurance +
                item.costs.insurance +
                item.costs.roadTax +
                item.costs.interest);

            totals[2] += item.sales;
            totals[3] += amortization;
            totals[4] += item.costs.motorInsurance;
            totals[5] += item.costs.insurance;
            totals[6] += item.costs.roadTax;
            totals[7] += item.costs.interest;
            totals[8] += cashflow;

            return {
              ...item,
              type: {
                ...item.type,
                amortization: amortization
              },
              cashflow: cashflow || 0
            };
          })
        : [],
    [data, totals]
  );

  return (
    <Table
      isLoading={loading}
      error={error}
      filter={
        <MonthPicker
          value={moment()
            .year(year)
            .month(month - 1)}
          onChange={handleMonthChange}
        />
      }
      components={{
        FilterRow: ({ columns }) => {
          return (
            <tr className={classes.totalsRow}>
              {columns.map((cellProps, key) => (
                <MTableCell
                  key={key}
                  columnDef={cellProps}
                  value={
                    key === 0
                      ? 'Spolu:'
                      : key === 1
                      ? (data && data.activeCarsInMonth.length) || 0
                      : key > 8
                      ? ''
                      : totals[key]
                  }
                />
              ))}
            </tr>
          );
        }
      }}
      columns={[
        {
          title: 'ŠPZ',
          field: 'number',
          cellStyle: {
            fontWeight: 'bold'
          },
          render: (children) =>
            children.number ? (
              <UILink component={Link} to={`/return-of-investment?car=${children.number}`}>
                {children.number}
              </UILink>
            ) : (
              children
            )
        },
        {
          title: 'Typ vozidla',
          field: 'type.name'
        },
        {
          title: 'Tržby z prenájmu',
          field: 'sales',
          type: 'currency'
        },
        {
          title: 'Predpokladaná amortizácia',
          field: 'type.amortization',
          type: 'currency',
          isCost: true
        },
        {
          title: 'PZP',
          field: 'costs.motorInsurance',
          type: 'currency',
          isCost: true
        },
        {
          title: 'KASKO',
          field: 'costs.insurance',
          type: 'currency',
          isCost: true
        },
        {
          title: 'Daň z motorových vozidiel',
          field: 'costs.roadTax',
          type: 'currency',
          isCost: true
        },
        {
          title: 'Úrok',
          field: 'costs.interest',
          type: 'currency',
          isCost: true
        },
        {
          title: 'Spolu',
          type: 'currency',
          field: 'cashflow',
          render: (data) => <Price greenPositive value={data.cashflow || data} />,
          headerStyle: {
            backgroundColor: theme.palette.action.selected
          },
          cellStyle: {
            backgroundColor: theme.palette.action.selected,
            fontWeight: 'bold',
            textAlign: 'right'
          }
        },
        {
          title: 'V predaji od',
          field: 'dateOfRentingEnd',
          render: ({ dateOfRentingEnd }) =>
            dateOfRentingEnd ? <DateString value={dateOfRentingEnd} /> : '',
          type: 'date',
          cellStyle: {
            whiteSpace: 'nowrap'
          }
        },
        {
          title: 'Dátum predaja',
          field: 'dateOfSale',
          render: ({ dateOfSale }) => (dateOfSale ? <DateString value={dateOfSale} /> : ''),
          type: 'date',
          cellStyle: {
            whiteSpace: 'nowrap'
          }
        }
      ]}
      data={formatedData}
      title="Mesačná výnosnosť"
      options={{
        paging: false,
        filtering: true,
        exportCsv: (columnsDef, dataToExport) => {
          const columns = ['Číslo', ...columnsDef.map((columnDef) => columnDef.title)];
          columns[9] = '';

          const data = formatData(columnsDef, dataToExport).map((item, key) => {
            const itemData = [key + 1, ...item];
            itemData[9] = '';
            return itemData;
          });

          exportCsvFile('Mesačná výnosnosť', columns, data);
        }
      }}
    />
  );
};
