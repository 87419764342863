import gql from 'graphql-tag';
import { RETURN_OF_INVESTMENT_CAR_HISTORY_FRAGMENT } from 'api/fragments';

export const UPDATE_CAR = gql`
  mutation UpdateCar($id: ID!, $data: CarInputUpdate!) {
    updateCar(id: $id, data: $data) {
      id
      pricelistPrice
      price
      salePrice
      realAmortization
      notes
    }
  }
`;

export const CREATE_CAR_HISTORY_EVENT = gql`
  mutation CreateCarHistoryEvent($carId: ID!, $data: HistoryEventInputCreate!) {
    createCarHistoryEvent(carId: $carId, data: $data) {
      id
      realAmortization
      history {
        id
        ...ReturnOnInvestmentCarHistoryFragment
      }
    }
  }
  ${RETURN_OF_INVESTMENT_CAR_HISTORY_FRAGMENT}
`;

export const UPDATE_CAR_HISTORY_EVENT = gql`
  mutation UpdateCarHistoryEvent($id: ID!, $data: HistoryEventInputUpdate!) {
    updateCarHistoryEvent(id: $id, data: $data) {
      id
      realAmortization
      history {
        id
        ...ReturnOnInvestmentCarHistoryFragment
      }
    }
  }
  ${RETURN_OF_INVESTMENT_CAR_HISTORY_FRAGMENT}
`;

export const DELETE_CAR_HISTORY_EVENT = gql`
  mutation DeleteCarHistoryEvent($id: ID!) {
    deleteCarHistoryEvent(id: $id) {
      id
      realAmortization
      history {
        id
        ...ReturnOnInvestmentCarHistoryFragment
      }
    }
  }
  ${RETURN_OF_INVESTMENT_CAR_HISTORY_FRAGMENT}
`;

export const UPDATE_CAR_COSTS = gql`
  mutation UpdateCarCosts(
    $id: ID!
    $month: Int!
    $year: Int!
    $motorInsurance: Float
    $insurance: Float
    $roadTax: Float
    $interest: Float
  ) {
    updateCarCosts(
      id: $id
      month: $month
      year: $year
      motorInsurance: $motorInsurance
      insurance: $insurance
      roadTax: $roadTax
      interest: $interest
    ) {
      id
      costs {
        id
        motorInsurance
        insurance
        roadTax
        interest
      }
      activeMonths {
        id
        month
        year
        costs {
          id
          motorInsurance
          insurance
          roadTax
          interest
        }
      }
    }
  }
`;

export const UPDATE_CAR_INTEREST_VALUE_MASS = gql`
  mutation UpdateCarInterestValueMass(
    $id: ID!
    $data: [InterestValueInput!]!
    $untilDate: DateTime
  ) {
    updateCarInterestValueMass(id: $id, data: $data) {
      id
      costs {
        id
        interest
      }
      activeMonthsForInterest: activeMonths(untilDate: $untilDate) {
        id
        month
        year
        costs {
          id
          interest
        }
      }
    }
  }
`;

export const PUT_CAR_ADDITIONAL_COST = gql`
  mutation PutCarAdditionalCost($carId: ID!, $costId: ID, $title: String!, $value: Float!) {
    putCarAdditionalCost(carId: $carId, costId: $costId, title: $title, value: $value) {
      id
      additionalCosts {
        id
        title
        value
      }
    }
  }
`;
