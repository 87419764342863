// @flow
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { DateString } from 'components/DateString';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

export type Props = {
  /**
   * Changelog item data
   */
  data: any
};

export const ChangelogTableRow = ({ data }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {data.version}
        </TableCell>
        <TableCell component="th" scope="row">
          <DateString value={data.timestamp} />
        </TableCell>
        <TableCell>
          {data.event}
          {data.method && (
            <>
              {' '}
              <Typography variant="caption" color="textSecondary">
                ({data.method})
              </Typography>
            </>
          )}
        </TableCell>
        <TableCell>{data.user}</TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} dangerouslySetInnerHTML={{ __html: data.diff }} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
