// @flow
import React from 'react';

import { NumberString } from 'components/NumberString';
import type { NumberStringProps } from 'components/NumberString';

export type Props = {} & NumberStringProps;

export const Percent = ({ value }: Props) => {
  return (
    <>
      <NumberString value={(value || 0) * 100} />%
    </>
  );
};
