// @flow
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import { UPDATE_CAR } from 'api/mutations';

import { GET_CAR_PRICES } from 'api/queries';

import MuiLink from '@material-ui/core/Link';

import { Table } from 'components/Table';

export const CarPrices = () => {
  const { loading, error, data } = useQuery(GET_CAR_PRICES);
  const [updateCar, { error: mutationError }] = useMutation(UPDATE_CAR);

  if (mutationError) {
    global.bugsnagClient.notify(mutationError);
  }

  const columns = useMemo(
    () => [
      {
        title: 'ŠPZ',
        field: 'number',
        cellStyle: {
          fontWeight: 'bold'
        },
        render: ({ number }) => (
          <MuiLink component={Link} to={`/return-of-investment?car=${number}`}>
            {number}
          </MuiLink>
        ),
        editable: 'never'
      },
      {
        title: 'Model',
        field: 'fullName',
        editable: 'never'
      },
      {
        title: 'Cenníková cena',
        field: 'pricelistPrice',
        type: 'currency'
      },
      {
        title: 'Obstarávacia cena',
        field: 'price',
        type: 'currency'
      },
      {
        title: 'Predajná cena',
        field: 'salePrice',
        type: 'currency'
      }
    ],
    []
  );

  const tableData = useMemo(() => {
    return (data && data.cars.map((item) => ({ ...item }))) || [];
  }, [data]);

  const options = useMemo(
    () => ({
      paging: false,
      filtering: false,
      exportButton: false
    }),
    []
  );

  const editableOptions = useMemo(
    () => ({
      isEditable: () => true,
      onRowUpdate: ({ __typename, id, number, fullName, ...data }) =>
        updateCar({
          variables: {
            id,
            data
          }
        })
    }),
    [updateCar]
  );

  return (
    <Table
      title="Ceny vozidiel"
      isLoading={loading}
      error={error}
      columns={columns}
      data={tableData}
      editable={editableOptions}
      options={options}
    />
  );
};
