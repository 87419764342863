// @flow
import React, { useContext } from 'react';
import styled from 'styled-components';

import { BrowserRouter as Router } from 'react-router-dom';

import {
  Root,
  getHeader,
  getDrawerSidebar,
  getSidebarTrigger,
  getSidebarContent,
  getCollapseBtn,
  getContent,
  getFooter,
  getCozyScheme
} from '@mui-treasury/layout';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';

import AccountCircle from '@material-ui/icons/AccountCircle';

import { MainMenu } from './Partials/MainMenu';
import { Routes } from './Partials/Routes';

import { AuthContext } from '../App';

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);
const Content = getContent(styled);
const Footer = getFooter(styled);

const scheme = getCozyScheme();

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    padding: spacing(2),
    [breakpoints.up('sm')]: {
      padding: spacing(3)
    }
  },
  title: {
    flexGrow: 1
  },
  footer: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    [breakpoints.up('sm')]: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3)
    }
  }
}));

export const Layout = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { user, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Root themeProvidedOmitted theme={theme} scheme={scheme}>
        {({ setOpen }) => (
          <>
            <Header>
              <Toolbar>
                <SidebarTrigger sidebarId="primarySidebar" />

                <Typography variant="h6" className={classes.title}>
                  EDENcars Infosystem
                </Typography>

                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>

                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListSubheader component="div" id="nested-list-subheader">
                      {user && user.displayName}
                    </ListSubheader>

                    <MenuItem onClick={handleLogout}>Odhlásiť sa</MenuItem>
                  </Menu>
                </div>
              </Toolbar>
            </Header>

            <Router>
              <DrawerSidebar sidebarId="primarySidebar">
                <SidebarContent>
                  <MainMenu onItemClick={() => setOpen('primarySidebar', false)} />
                </SidebarContent>

                <CollapseBtn />
              </DrawerSidebar>

              <Content className={classes.root}>
                <Routes />
              </Content>
            </Router>

            <Footer className={classes.footer}>
              <Typography variant="caption" color="textSecondary">
                &copy; EDENcars, s.r.o. {new Date().getFullYear()}. Všetky práva vyhradené.
              </Typography>
            </Footer>
          </>
        )}
      </Root>
    </MuiPickersUtilsProvider>
  );
};
