// @flow
import React, { useMemo, useRef, useCallback } from 'react';
import moment from 'moment';
import { MTableCell } from 'material-table';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useQuery } from '@apollo/client';

import { GET_YEAR_PROFIT_STATISTICS } from 'api/queries';

import { Table } from 'components/Table';
import { YearPicker } from 'components/YearPicker';
import { useQueryString } from 'components/useQueryString';

const useStyles = makeStyles((theme) => ({
  totalsRow: {
    backgroundColor: theme.palette.action.selected
  },
  yearPicker: {
    marginRight: theme.spacing(2)
  }
}));

export const YearProfitStatistics = () => {
  const classes = useStyles();

  const [year, setYear] = useQueryString('year', moment().year());
  const [shortTerm, setShortTerm] = useQueryString('shortTerm', 'true');
  const [longTerm, setLongTerm] = useQueryString('longTerm', 'true');

  const { loading, error, data } = useQuery(GET_YEAR_PROFIT_STATISTICS, {
    variables: {
      year: parseInt(year),
      shortTerm: shortTerm === 'true',
      longTerm: longTerm === 'true'
    }
  });

  const handleYearChange = useCallback(
    (value) => {
      setYear(value.year());
    },
    [setYear]
  );

  const handleShortTermChange = useCallback(() => {
    setShortTerm((!(shortTerm === 'true')).toString());
  }, [setShortTerm, shortTerm]);

  const handleLongTermChange = useCallback(() => {
    setLongTerm((!(longTerm === 'true')).toString());
  }, [setLongTerm, longTerm]);

  const feeTranslations = useRef({
    childSeat: 'Detská sedačka',
    boosterSeat: 'Podsedák',
    navigation: 'Navigácia',
    skiHolder: 'Nosič lyží',
    snowChains: 'Snehové reťaze',
    winterTires: 'Zimné gumy',
    crossBorder: 'Povolenie vycestovať',
    additionalDriver: 'Ďalší vodič',
    youngDrived: 'Mladý vodič',
    outOfHoursService: 'Mimopracovný servis',
    pickUp: 'Pristavenie',
    dropOff: 'Odstavenie',
    gpsMonitoring: 'GPS monitoring',
    additionalFee: 'Iný poplatok',
    demageInsurance: 'Poistenie škody 5%',
    demageInsuranceExtra: 'Poistenie škody 0%',
    theftInsurance: 'Postenie krádeže',
    totalInsurance: 'Celkové poistenie',
    refueling: 'Dotankovanie',
    administration: 'Administratívny poplatok',
    overlimit: 'Nadlimit km',
    demageWithInsurance: 'Poškodenie',
    demageWithInsurance0vat: 'Spoluúčasť',
    cleaning: 'Čistenie/tepovanie',
    outOfServiceInterval: 'Nedodržanie servisného intervalu',
    prematureReturn: 'Predčasné vrátenie',
    notBuyCar: 'Neodkup vozidla - pokuta',
    reinvoicing: 'Refakturácia DZ s 0%',
    anotherFee: 'Iný poplatok'
  });

  const tableData = useMemo(() => {
    if (!data || !data.months) {
      return [];
    }

    const feeData = {};

    data.months.forEach((month, key) =>
      month.fees.forEach((fee) => {
        if (!feeData[fee.title]) {
          feeData[fee.title] = {};
        }

        feeData[fee.title][key] = fee.value || 0;
      })
    );

    return [
      {
        title: 'Prenájom',
        m1: data.months[0].rentalPriceSum,
        m2: data.months[1].rentalPriceSum,
        m3: data.months[2].rentalPriceSum,
        m4: data.months[3].rentalPriceSum,
        m5: data.months[4].rentalPriceSum,
        m6: data.months[5].rentalPriceSum,
        m7: data.months[6].rentalPriceSum,
        m8: data.months[7].rentalPriceSum,
        m9: data.months[8].rentalPriceSum,
        m10: data.months[9].rentalPriceSum,
        m11: data.months[10].rentalPriceSum,
        m12: data.months[11].rentalPriceSum,
        total: Object.keys(data.months).reduce(
          (sum, key) => sum + data.months[key].rentalPriceSum || 0,
          0
        )
      },
      ...Object.keys(feeData).map((feeTitle) => ({
        title: feeTranslations.current[feeTitle] || feeTitle,
        m1: feeData[feeTitle][0],
        m2: feeData[feeTitle][1],
        m3: feeData[feeTitle][2],
        m4: feeData[feeTitle][3],
        m5: feeData[feeTitle][4],
        m6: feeData[feeTitle][5],
        m7: feeData[feeTitle][6],
        m8: feeData[feeTitle][7],
        m9: feeData[feeTitle][8],
        m10: feeData[feeTitle][9],
        m11: feeData[feeTitle][10],
        m12: feeData[feeTitle][11],
        total: Object.keys(feeData[feeTitle]).reduce(
          (sum, key) => sum + feeData[feeTitle][key] || 0,
          0
        )
      }))
    ];
  }, [data]);

  const totals = useMemo(
    () =>
      tableData.reduce(
        (totals, item) => [
          0,
          totals[1] + (item.m1 || 0),
          totals[2] + (item.m2 || 0),
          totals[3] + (item.m3 || 0),
          totals[4] + (item.m4 || 0),
          totals[5] + (item.m5 || 0),
          totals[6] + (item.m6 || 0),
          totals[7] + (item.m7 || 0),
          totals[8] + (item.m8 || 0),
          totals[9] + (item.m9 || 0),
          totals[10] + (item.m10 || 0),
          totals[11] + (item.m11 || 0),
          totals[12] + (item.m12 || 0),
          totals[13] + (item.total || 0)
        ],
        new Array(14).fill(0)
      ),
    [tableData]
  );

  return (
    <Table
      isLoading={loading}
      error={error}
      filter={
        <>
          <YearPicker
            value={moment().year(year)}
            onChange={handleYearChange}
            className={classes.yearPicker}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={shortTerm === 'true'}
                onChange={handleShortTermChange}
                color="primary"
              />
            }
            label="Krátkodobé zmluvy"
          />

          <FormControlLabel
            control={<Checkbox checked={longTerm === 'true'} onChange={handleLongTermChange} />}
            label="Dlhodobé zmluvy"
          />
        </>
      }
      components={{
        FilterRow: ({ columns }) => {
          return (
            <tr className={classes.totalsRow}>
              {columns.map((cellProps, key) => (
                <MTableCell
                  key={key}
                  columnDef={cellProps}
                  value={key === 0 ? 'Spolu:' : totals[key]}
                />
              ))}
            </tr>
          );
        }
      }}
      columns={[
        { title: '', field: 'title', cellStyle: { fontWeight: 'bold' } },
        { title: '1/' + year, field: 'm1', type: 'currency' },
        { title: '2/' + year, field: 'm2', type: 'currency' },
        { title: '3/' + year, field: 'm3', type: 'currency' },
        { title: '4/' + year, field: 'm4', type: 'currency' },
        { title: '5/' + year, field: 'm5', type: 'currency' },
        { title: '6/' + year, field: 'm6', type: 'currency' },
        { title: '7/' + year, field: 'm7', type: 'currency' },
        { title: '8/' + year, field: 'm8', type: 'currency' },
        { title: '9/' + year, field: 'm9', type: 'currency' },
        { title: '10/' + year, field: 'm10', type: 'currency' },
        { title: '11/' + year, field: 'm11', type: 'currency' },
        { title: '12/' + year, field: 'm12', type: 'currency' },
        {
          title: 'Spolu',
          field: 'total',
          type: 'currency',
          cellStyle: { fontWeight: 'bold' }
        }
      ]}
      data={tableData}
      title="Ročná štatistika"
      options={{ paging: false, filtering: true }}
    />
  );
};
