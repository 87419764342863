// @flow
import React, { memo, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { UPDATE_CAR } from 'api/mutations';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { Loading } from 'components/Loading';

import type { GetReturnOfInvestmentStatistics_car as GetReturnOfInvestmentStatisticsCar } from 'api/__generated__/GetReturnOfInvestmentStatistics';

const useStyles = makeStyles((theme) => ({
  notes: {
    marginBottom: '0 !important'
  },
  buttonWrap: {
    marginTop: theme.spacing(),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export type Props = {
  car: GetReturnOfInvestmentStatisticsCar
};

export const CarNotes = memo(({ car }: Props) => {
  const classes = useStyles();

  const [updateCar, { loading, error }] = useMutation(UPDATE_CAR);

  const handleFormSubmit = useCallback(
    (values) => {
      return updateCar({
        variables: {
          id: car.id,
          data: {
            notes: values.notes
          }
        }
      });
    },
    [car.id, updateCar]
  );

  if (!car) {
    return null;
  }

  return loading ? (
    <Loading />
  ) : (
    <Formik initialValues={{ notes: car.notes || '' }} onSubmit={handleFormSubmit}>
      {({ touched }) => (
        <>
          <Form>
            <Field
              component={TextField}
              fullWidth
              multiline
              variant="outlined"
              name="notes"
              label="Poznámky"
              margin="none"
              classes={{ root: classes.notes }}
            />

            {error}

            {touched.notes ? (
              <div className={classes.buttonWrap}>
                <Button type="submit" size="small">
                  Uložiť
                </Button>
              </div>
            ) : null}
          </Form>
        </>
      )}
    </Formik>
  );
});
