// @flow
import React, { memo } from 'react';

// import { makeStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import { GET_CAR_CHANGELOG } from 'api/queries';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Loading } from 'components/Loading';

import { ChangelogTableRow } from './ChangelogTableRow';

// const useStyles = makeStyles((theme) => ({}));

export type Props = {
  /**
   * Car id
   */
  carId: string,
  /**
   * Whether to open dialog
   */
  open: boolean,
  /**
   * Callback to run on close request
   */
  onClose: () => void
};

export const ChangelogDialog = memo(({ carId, open, onClose }: Props) => {
  // const classes = useStyles();

  const { loading, error, data } = useQuery(GET_CAR_CHANGELOG, {
    variables: {
      carId
    },
    skip: !carId || !open
  });

  if (error) {
    global.bugsnagClient.notify(error);
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Changelog</DialogTitle>

      <DialogContent>
        {loading ? (
          <Loading />
        ) : error ? (
          <Typography variant="body2" color="danger">
            {error.message}
          </Typography>
        ) : data ? (
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Verzia</TableCell>
                <TableCell>Dátum</TableCell>
                <TableCell>Udalosť</TableCell>
                <TableCell>Používateľ</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {data.car.changelog.map((row) => (
                <ChangelogTableRow key={row.id} data={row} />
              ))}
            </TableBody>
          </Table>
        ) : null}
      </DialogContent>
    </Dialog>
  );
});
