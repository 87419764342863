// @flow
import React, { useMemo, useCallback } from 'react';
import moment from 'moment';

// import { makeStyles } from '@material-ui/core/styles';

import { useMutation } from '@apollo/client';
import { CREATE_CAR_HISTORY_EVENT, UPDATE_CAR_HISTORY_EVENT } from 'api/mutations';

import * as yup from 'yup';
import { Formik, Form } from 'formik';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import { HistoryEventForm } from '../Forms/HistoryEventForm';

import type { ReturnOnInvestmentCarHistoryFragment } from 'api/__generated__/ReturnOnInvestmentCarHistoryFragment';

export type Props = {
  open: boolean,
  carId: string,
  historyEvent: ReturnOnInvestmentCarHistoryFragment | null,
  onClose: () => void
};

// const useStyles = makeStyles(theme => ({

// }));

export const CarHistoryEventFormDialog = ({ open, carId, historyEvent, onClose }: Props) => {
  // const classes = useStyles();

  const [createHistoryEvent, { error: createError }] = useMutation(CREATE_CAR_HISTORY_EVENT, {
    refetchQueries: ['GetReturnOfInventoryStatisticsMonths']
  });
  const [updateHistoryEvent, { error: updateError }] = useMutation(UPDATE_CAR_HISTORY_EVENT, {
    refetchQueries: ['GetReturnOfInventoryStatisticsMonths']
  });

  if (createError) {
    global.bugsnagClient.notify(createError);
  }
  if (updateError) {
    global.bugsnagClient.notify(updateError);
  }

  const handleFormSubmit = useCallback(
    (values, { setSubmitting }) => {
      const data = { ...values };

      // use end of date for some events
      if (['END_OF_RENTING', 'SALE'].includes(data.name)) {
        data.date = moment(data.date).endOf('day').toDate();
      }

      const mutation = historyEvent
        ? updateHistoryEvent({
            variables: {
              id: historyEvent.id,
              data
            }
          })
        : createHistoryEvent({
            variables: {
              carId,
              data
            }
          });

      return mutation
        .then(() => {
          onClose();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [carId, historyEvent, createHistoryEvent, updateHistoryEvent, onClose]
  );

  const initialValues = useMemo(
    () => ({
      name: (historyEvent && historyEvent.name) || 'END_OF_RENTING',
      date: (historyEvent && moment(historyEvent.date)) || moment(),
      description: (historyEvent && historyEvent.description) || ''
    }),
    [historyEvent]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(),
        date: yup.date().required(),
        description: yup.string()
      }),
    []
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" aria-labelledby="form-dialog-title">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>

            <DialogContent>
              <HistoryEventForm />
            </DialogContent>

            <DialogActions>
              <Button autoFocus disabled={isSubmitting} onClick={onClose}>
                Zrušiť
              </Button>

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Uložiť'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
