// @flow
import React, { memo, useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import { DELETE_CAR_HISTORY_EVENT } from 'api/mutations';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';

import { DateString } from 'components/DateString';

import { CarHistoryEventFormDialog } from './CarHistoryEventFormDialog';

import type { ReturnOnInvestmentCarHistoryFragment } from 'api/__generated__/ReturnOnInvestmentCarHistoryFragment';

export type Props = {
  carId: string,
  history: ReturnOnInvestmentCarHistoryFragment[]
};

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 0
  }
}));

export const CarHistory = memo(({ carId, history }: Props) => {
  const classes = useStyles();

  const [deleteHistoryEvent, { error }] = useMutation(DELETE_CAR_HISTORY_EVENT, {
    refetchQueries: ['GetReturnOfInventoryStatisticsMonths']
  });

  if (error) {
    global.bugsnagClient.notify(error);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [historyEvent, setHistoryEvent] = useState(null);

  const nameToTitle = useCallback((name: string) => {
    switch (name) {
      case 'START_OF_RENTING':
        return 'Začatie prenajímania';
      case 'END_OF_RENTING':
        return 'Ukončenie prenajímania';
      case 'TERMINATE_INSURANCE':
        return 'Ukončenie KASKO';
      case 'REMOVE_FROM_REGISTER':
        return 'Vyradenie z evidencie';
      case 'SALE':
        return 'Predaj';
      default:
        return name;
    }
  }, []);

  const handleMenuOpen = useCallback((event, data) => {
    setHistoryEvent(data);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // const handleMenuOpen = (event, index) => {
  //   setSelectedIndex(index);
  //   setAnchorEl(null);
  // };

  const handleAddButtonClick = useCallback(() => {
    setHistoryEvent(null);
    setDialogOpen(true);
  }, []);

  const hadleEditButtonClick = useCallback(() => {
    setDialogOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const hadleDeleteButtonClick = useCallback(() => {
    if (historyEvent) {
      deleteHistoryEvent({ variables: { id: historyEvent.id } });
    }
    handleMenuClose();
  }, [deleteHistoryEvent, handleMenuClose, historyEvent]);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <CardHeader
        subheader="História vozidla"
        className={classes.title}
        action={
          <IconButton size="small" aria-label="add" onClick={handleAddButtonClick}>
            <AddIcon />
          </IconButton>
        }
      />

      <Table aria-label="simple table" size="small">
        <TableBody>
          {[...history]
            .sort((a, b) => a.date - b.date)
            .map((historyEvent) => (
              <TableRow key={historyEvent.id}>
                <TableCell component="th" scope="row" variant="head">
                  {nameToTitle(historyEvent.name)}
                  {historyEvent.description ? (
                    <Typography variant="caption" color="textSecondary" component="p">
                      {historyEvent.description}
                    </Typography>
                  ) : null}
                </TableCell>

                <TableCell align="right">
                  <DateString value={historyEvent.date} />
                </TableCell>

                <TableCell align="right" padding="checkbox">
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleMenuOpen(e, historyEvent)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={hadleEditButtonClick}>Upraviť</MenuItem>
        <MenuItem onClick={hadleDeleteButtonClick}>Odstrániť</MenuItem>
      </Menu>

      <CarHistoryEventFormDialog
        open={dialogOpen}
        carId={carId}
        historyEvent={historyEvent || null}
        onClose={handleDialogClose}
      />
    </>
  );
});
