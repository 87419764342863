// @flow
import { ApolloClient, InMemoryCache, ApolloLink, from, makeVar } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { sha256 } from 'crypto-hash';

export const authorizationToken = makeVar(null);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, locations, path } = error;
      global.bugsnagClient.notify(error instanceof Error ? error : new Error(message), (event) => {
        event.addMetadata('error', error);
      });
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const persistedQueriesLink = createPersistedQueryLink({ sha256 });

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${authorizationToken()}`
    }
  });

  return forward(operation);
});

const batchHttpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_API_URL
});

const link = from([errorLink, persistedQueriesLink, authLink, batchHttpLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});
