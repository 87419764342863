// @flow
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { GET_WRONG_HISTORY_CARS } from 'api/queries';

import MuiLink from '@material-ui/core/Link';

import { Table } from 'components/Table';
import { DateString } from 'components/DateString';

export const WrongHistoryCars = () => {
  const { loading, error, data } = useQuery(GET_WRONG_HISTORY_CARS);

  const tableData = useMemo(() => {
    return (data && data.wrongHistoryCars.map((item) => ({ ...item }))) || [];
  }, [data]);

  return (
    <Table
      isLoading={loading}
      error={error}
      columns={[
        {
          title: 'ŠPZ',
          field: 'number',
          cellStyle: {
            fontWeight: 'bold'
          },
          render: ({ number }) => (
            <MuiLink component={Link} to={`/return-of-investment?car=${number}`}>
              {number}
            </MuiLink>
          )
        },
        {
          title: 'Dátum začatia prenajímania',
          render: ({ startOfRentingDate }) =>
            startOfRentingDate ? <DateString value={startOfRentingDate} /> : '',
          type: 'date',
          cellStyle: {
            whiteSpace: 'nowrap'
          }
        },
        {
          title: 'Dátum ukončenia prenajímania',
          render: ({ saleDate }) => (saleDate ? <DateString value={saleDate} /> : ''),
          type: 'date',
          cellStyle: {
            whiteSpace: 'nowrap'
          }
        }
      ]}
      data={tableData}
      title="Autá so zle zadanými dátumami v histórií"
      options={{
        paging: false,
        filtering: false,
        exportButton: false,
        search: false
      }}
    />
  );
};
