// @flow
import React, { memo } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { GET_RETURN_OF_INVESTMENT_STATISTICS_MONTHS } from 'api/queries';
import { UPDATE_CAR_COSTS } from 'api/mutations';

import { useTheme } from '@material-ui/core/styles';
import { Table } from 'components/Table';
import { Loading } from 'components/Loading';
import { Price } from 'components/Price';

export type Props = {
  carId: string
};

export const ActiveMonthsTable = memo(({ carId }: Props) => {
  const theme = useTheme();

  const [updateCarCosts, { error: mutationError }] = useMutation(UPDATE_CAR_COSTS);
  const { loading, error, data } = useQuery(GET_RETURN_OF_INVESTMENT_STATISTICS_MONTHS, {
    variables: {
      carId: carId
    }
  });

  if (mutationError) {
    global.bugsnagClient.notify(mutationError);
  }

  if (loading) {
    return <Loading />;
  }

  const tableData =
    data && data.car
      ? data.car.activeMonths.map((row) => ({
          ...row,
          total:
            row.sales +
            -row.amortization +
            -row.costs.motorInsurance +
            -row.costs.insurance +
            -row.costs.roadTax +
            -row.costs.interest
        }))
      : [];

  return (
    <Table
      isLoading={loading}
      error={error}
      editable={{
        isEditable: () => true,
        onRowUpdate: (newData, oldData) => {
          const motorInsurance =
            newData.costs.motorInsurance &&
            parseFloat(newData.costs.motorInsurance.toString().replace(/,/g, '.'));
          const insurance =
            newData.costs.insurance &&
            parseFloat(newData.costs.insurance.toString().replace(/,/g, '.'));
          const roadTax =
            newData.costs.roadTax &&
            parseFloat(newData.costs.roadTax.toString().replace(/,/g, '.'));
          const interest =
            newData.costs.interest &&
            parseFloat(newData.costs.interest.toString().replace(/,/g, '.'));

          return updateCarCosts({
            variables: {
              id: carId,
              month: parseInt(oldData.month),
              year: parseInt(oldData.year),
              motorInsurance:
                !isNaN(motorInsurance) && motorInsurance !== '' ? motorInsurance : null,
              insurance: !isNaN(insurance) && insurance !== '' ? insurance : null,
              roadTax: !isNaN(roadTax) && roadTax !== '' ? roadTax : null,
              interest: !isNaN(interest) && interest !== '' ? interest : null
            }
          });
        }
      }}
      columns={[
        {
          title: 'Mesiac',
          render: ({ month, year }) => `${month}/${year}`,
          cellStyle: {
            fontWeight: 'bold'
          }
        },
        {
          title: 'Tržby z prenájmu',
          field: 'sales',
          type: 'currency',
          editable: 'never'
        },
        {
          title: 'Skutočná amortizácia',
          field: 'amortization',
          type: 'currency',
          editable: 'never',
          isCost: true
        },
        {
          title: 'PZP',
          field: 'costs.motorInsurance',
          type: 'currency',
          editable: 'onUpdate',
          isCost: true
        },
        {
          title: 'KASKO',
          field: 'costs.insurance',
          type: 'currency',
          editable: 'onUpdate',
          isCost: true
        },
        {
          title: 'Daň z motorových vozidiel',
          field: 'costs.roadTax',
          type: 'currency',
          editable: 'onUpdate',
          isCost: true
        },
        {
          title: 'Úrok',
          field: 'costs.interest',
          type: 'currency',
          editable: 'onUpdate',
          isCost: true
        },
        {
          title: 'Spolu',
          field: 'total',
          render: ({ total }) => <Price greenPositive value={total} />,
          editable: 'never',
          type: 'currency',
          headerStyle: {
            backgroundColor: theme.palette.action.selected
          },
          cellStyle: {
            backgroundColor: theme.palette.action.selected,
            fontWeight: 'bold',
            textAlign: 'right'
          }
        }
      ]}
      data={tableData}
      title="Štatistiky po mesiacoch"
      options={{ paging: false }}
    />
  );
});
