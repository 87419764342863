// @flow
import React from 'react';

import { NumberString } from 'components/NumberString';
import type { NumberStringProps } from 'components/NumberString';

export type Props = {} & NumberStringProps;

export const Weight = ({ value }: Props) => {
  return (
    <>
      <NumberString value={value} decimals={0} />
      &nbsp;kg
    </>
  );
};
