// @flow
import React, { useState, useCallback } from 'react';
import type { Node } from 'react';
import classnames from 'classnames';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '@material-ui/icons/EditOutlined';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';

import { PriceInput } from 'components/PriceInput';

export type Props = {
  /**
   * Value to change
   */
  value: number,
  /**
   * Render children
   */
  children: Node,
  /**
   * Callback to run on value change
   */
  onChange: ({ value: number, [key: string]: any }) => Promise<void>,
  /**
   * Props to forward to TextField
   */
  textFieldProps: any,
  /**
   * Additional data to append to onChange callback
   */
  data?: { [key: string]: any }
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 11,
    paddingBottom: 11,
    '&:hover, &:active, &:focus': {
      '& $icon': {
        display: 'inline-block'
      }
    }
  },
  icon: {
    display: 'none',
    marginRight: theme.spacing()
  },
  content: {
    display: 'inline-block',
    height: 26,
    lineHeight: '26px',
    verticalAlign: 'middle'
  },
  form: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginRight: theme.spacing()
  }
}));

const validationSchema = yup.object().shape({
  value: yup.number().required().default(0)
});

export const EditableCell = ({
  value,
  onChange,
  children,
  textFieldProps,
  className,
  data,
  ...passingProps
}: Props) => {
  const classes = useStyles();

  const [editMode, setEditMode] = useState(false);

  const startEditMode = useCallback(() => {
    setEditMode(true);
  }, []);

  const endEditMode = useCallback(() => {
    setEditMode(false);
  }, []);

  const handleSubmit = useCallback(
    ({ value }, { setSubmitting }) => {
      onChange({ ...data, value }).then(() => {
        setSubmitting(false);
        setEditMode(false);
      });
    },
    [onChange, data]
  );

  return (
    <TableCell className={classnames(classes.root, className)} {...passingProps}>
      {editMode ? (
        <Formik
          initialValues={validationSchema.cast({ value })}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <PriceInput
                autoFocus
                name="value"
                margin="none"
                className={classes.input}
                {...textFieldProps}
              />

              <IconButton
                type="submit"
                size="small"
                onClick={startEditMode}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckIcon fontSize="small" color="primary" />
                )}
              </IconButton>

              <IconButton size="small" onClick={endEditMode}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <IconButton size="small" onClick={startEditMode} className={classes.icon}>
            <EditIcon fontSize="small" />
          </IconButton>

          <div className={classes.content}>{children}</div>
        </>
      )}
    </TableCell>
  );
};
