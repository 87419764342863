// @flow
import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import MaterialTable, { MTableToolbar, MTableBody } from 'material-table';
import { CsvBuilder } from 'filefy';

import { makeStyles } from '@material-ui/core/styles';

import { ErrorMessage } from 'components/ErrorMessage';
import { Price } from 'components/Price';

import type { Node } from 'react';
import type MaterialTableType from 'material-table';

export type Props = MaterialTableType & {
  error?: string,
  filter?: Node
};

const useStyles = makeStyles((theme) => ({
  root: {},
  filterWrap: {
    display: 'flex',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}));

const byString = (o, s) => {
  if (!s) {
    return;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const x = a[i];
    if (o && x in o) {
      o = o[x];
    } else {
      return;
    }
  }
  return o;
};

export const Table = ({
  error,
  filter,
  columns,
  options = {},
  className,
  components,
  ...passingProps
}: Props) => {
  const classes = useStyles();

  if (error) {
    global.bugsnagClient.notify(error);
  }

  return (
    <div className={classnames(classes.root, className)}>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />

              {filter ? <div className={classes.filterWrap}>{filter}</div> : null}
            </div>
          ),
          Body: (props) => {
            if (error) {
              return (
                <tr>
                  <td colSpan={999}>
                    <ErrorMessage>{error.message}</ErrorMessage>
                  </td>
                </tr>
              );
            }

            return <MTableBody {...props} />;
          },
          ...components
        }}
        columns={columns.map((column) => {
          if (column.type === 'currency') {
            column.render =
              typeof column.render === 'function'
                ? column.render
                : (data) => (
                    <Price
                      style={column.cellStyle}
                      value={(typeof data === 'object' ? byString(data, column.field) : data) || 0}
                      isCost={!!column.isCost}
                    />
                  );
            column.headerStyle = { paddingRight: 0, textAlign: 'right', whiteSpace: 'nowrap' };
          }

          return column;
        })}
        options={{
          exportButton: true,
          exportCsv: (columns, dataToExport) => {
            const data = formatData(columns, dataToExport);

            exportCsvFile(
              passingProps.title,
              columns.map((columnDef) => columnDef.title),
              data
            );
          },
          ...options
        }}
        localization={{
          body: {
            editTooltip: 'Upraviť',
            editRow: {
              cancelTooltip: 'Zrušiť',
              saveTooltip: 'Uložiť'
            }
          },
          header: {
            actions: 'Akcie'
          },
          toolbar: {
            exportTitle: 'Exportovať',
            exportAriaLabel: 'Exportovať',
            exportName: 'Exportovať ako CSV',
            searchTooltip: 'Vyhľadať v tabuľke',
            searchPlaceholder: 'Vyhľadať v tabuľke'
          }
        }}
        {...passingProps}
      />
    </div>
  );
};

export const formatData = (columns: any[], data: any[]): any[] => {
  return data.map((rowData) =>
    columns.map((columnDef) => {
      let value = columnDef.render ? columnDef.render(rowData) : false;

      if (typeof value !== 'string' && typeof value !== 'number') {
        value = byString(rowData, columnDef.field);
      }

      return columnDef.type === 'date'
        ? value
          ? moment(value).format('DD.MM.YYYY HH:mm:ss')
          : ''
        : typeof value === 'number'
        ? value.toFixed(2).toString().replace(/\./, ',')
        : value || '';
    })
  );
};

export const exportCsvFile = (title: string, columns: string[], data: any[]) => {
  const builder = new CsvBuilder((title || 'data') + '.csv');
  builder.setDelimeter(',').setColumns(columns).addRows(data).exportFile();
};
