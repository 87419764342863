// @flow
import React, { useCallback } from 'react';
import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export type Props = {} & TextField;

export const GET_CARS_QUERY = gql`
  query GetCarsForCarsField {
    cars {
      id
      numbers
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 180
  }
}));

export const CarsField = ({ value, onChange, ...passingProps }: Props) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_CARS_QUERY);

  const handleChange = useCallback(
    (event, newValue) => {
      onChange(newValue);
    },
    [onChange]
  );

  if (error) {
    global.bugsnagClient.notify(error);
    return null;
  }

  return (
    <Autocomplete
      id="cars"
      loading={loading}
      options={
        (data &&
          data.cars
            .reduce((numbers, car) => [...numbers, ...car.numbers], [])
            .sort((a, b) => a.localeCompare(b))) ||
        []
      }
      className={classes.root}
      getOptionLabel={(option) => option}
      getOptionSelected={(option, value) => option === value}
      onChange={handleChange}
      value={value}
      renderInput={(params) => (
        <TextField {...params} label="Vozidlo" variant="outlined" fullWidth {...passingProps} />
      )}
    />
  );
};
