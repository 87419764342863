// @flow
import React from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';

import type { Element } from 'react';

export type Props = {|
  /**
   * Link text
   */
  primaryText: string,
  /**
   * Link absolute url
   */
  to: string,
  /**
   * Link icon
   */
  icon: Element<any>,
  /**
   * Callback to run on item click
   */
  onClick?: () => void
|};

export const MainMenuLink = ({ primaryText, to, icon, onClick }: Props) => {
  const match = useRouteMatch({
    path: to,
    exact: true
  });

  return (
    <ListItem selected={!!match} button component={Link} to={to} onClick={onClick}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={primaryText} primaryTypographyProps={{ noWrap: true }} />
    </ListItem>
  );
};
