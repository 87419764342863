// @flow
import React, { memo } from 'react';
import moment from 'moment';

import { MTableToolbar } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import { GET_RETURN_OF_INVESTMENT_STATISTICS_CONTRACTS } from 'api/queries';

import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

import { Table } from 'components/Table';
import { Loading } from 'components/Loading';
import { DateTimeString } from 'components/DateTimeString';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6)
  },
  contactTypeChip: {
    width: theme.spacing(1),
    height: theme.spacing(1)
  },
  contractsTableToolbar: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    '& $contactTypeChip': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1)
    }
  }
}));

export type Props = {
  carId: string
};

export const ContractsTable = memo(({ carId }: Props) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_RETURN_OF_INVESTMENT_STATISTICS_CONTRACTS, {
    variables: {
      carId: carId
    }
  });

  return loading ? (
    <Loading />
  ) : (
    <Table
      className={classes.root}
      isLoading={loading}
      error={error}
      columns={[
        {
          render: ({ type }) => (
            <Chip
              color={
                type === 'SHORT_TERM' ? 'primary' : type === 'LONG_TERM' ? 'secondary' : 'default'
              }
              className={classes.contactTypeChip}
            />
          ),
          cellStyle: {
            textAlign: 'center'
          },
          sorting: false
        },
        {
          title: 'Číslo zluvy',
          field: 'number',
          cellStyle: {
            fontWeight: 'bold'
          }
        },
        {
          title: 'Číslo faktúry',
          field: 'invoiceNumber'
        },
        {
          title: 'Zákazník',
          field: 'tenant'
        },
        {
          title: 'Prenájom od',
          field: 'rentalStartDate',
          render: ({ rentalStartDate }) => <DateTimeString value={rentalStartDate} />,
          type: 'date',
          cellStyle: {
            whiteSpace: 'nowrap'
          }
        },
        {
          title: 'Prenájom do',
          field: 'rentalEndDate',
          render: ({ rentalEndDate }) => <DateTimeString value={rentalEndDate} />,
          type: 'date',
          cellStyle: {
            whiteSpace: 'nowrap'
          }
        },
        {
          title: 'Počet dní',
          field: 'numberOfDays',
          type: 'numeric'
        },
        {
          title: 'Cena nájmu',
          field: 'rentalPrice',
          type: 'currency'
        },
        {
          title: 'DPH',
          field: 'rentalPriceVat',
          type: 'currency',
          cellStyle: {
            fontSize: '0.8em'
          },
          headerStyle: {
            fontSize: '0.8em'
          }
        },
        {
          title: 'Príplatky',
          field: 'feeSum',
          type: 'currency'
        },
        {
          title: 'DPH',
          field: 'feeSumVat',
          type: 'currency',
          cellStyle: {
            fontSize: '0.8em'
          },
          headerStyle: {
            fontSize: '0.8em'
          }
        },
        {
          title: 'Celková cena',
          field: 'total',
          type: 'currency',
          cellStyle: {
            fontWeight: 'bold'
          }
        },
        {
          title: 'DPH',
          field: 'totalVat',
          type: 'currency',
          cellStyle: {
            fontSize: '0.8em'
          },
          headerStyle: {
            fontSize: '0.8em'
          }
        }
      ]}
      components={{
        Toolbar: (props) => (
          <div>
            <MTableToolbar {...props} />

            <div className={classes.contractsTableToolbar}>
              <Chip color="primary" className={classes.contactTypeChip} />
              <Typography variant="caption">Krátkodobé zmluvy</Typography>

              <Chip color="secondary" className={classes.contactTypeChip} />
              <Typography variant="caption">Dlhodobé zmluvy</Typography>
            </div>
          </div>
        )
      }}
      data={
        data && data.car
          ? data.car.contracts.map((item) => ({
              ...item,
              invoiceNumber: item.invoice ? item.invoice.number : '',
              numberOfDays: moment(item.rentalEndDate).diff(moment(item.rentalStartDate), 'days'),
              rentalPriceVat: (item.rentalPrice * item.vat) / 100,
              feeSumVat: (item.feeSum * item.vat) / 100,
              total: item.rentalPrice + item.feeSum,
              totalVat: ((item.rentalPrice + item.feeSum) * item.vat) / 100
            }))
          : []
      }
      title="Zmluvy"
      options={{
        paging: false,
        rowStyle: (rowData) => ({
          textDecoration: rowData.status === 'Zrušená' ? 'line-through' : undefined,
          color: rowData.status === 'Zrušená' ? 'rgba(0, 0, 0, 0.38)' : undefined
        })
      }}
    />
  );
});
