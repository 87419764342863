import gql from 'graphql-tag';
import {
  RETURN_OF_INVESTMENT_CAR_INFO_FRAGMENT,
  RETURN_OF_INVESTMENT_CAR_RETURNS_FRAGMENT,
  RETURN_OF_INVESTMENT_CAR_HISTORY_FRAGMENT,
  RETURN_OF_INVESTMENT_INTEREST_DIALOG_FRAGMENT
} from 'api/fragments';

export const GET_RATE_OF_PROFIT_STATISTICS = gql`
  query GetRateOfProfitStatistics($year: Int!, $month: Int!) {
    activeCarsInMonth(year: $year, month: $month) {
      id
      number
      type {
        id
        name
        amortization
      }
      sales(year: $year, month: $month)
      costs(year: $year, month: $month) {
        id
        motorInsurance
        insurance
        roadTax
        interest
      }
      dateOfRentingEnd
      dateOfSale
    }
  }
`;

export const GET_YEAR_PROFIT_STATISTICS = gql`
  query GetYearProfitStatistics($year: Int!, $shortTerm: Boolean, $longTerm: Boolean) {
    months(year: $year) {
      id
      rentalPriceSum(shortTerm: $shortTerm, longTerm: $longTerm)
      fees(shortTerm: $shortTerm, longTerm: $longTerm) {
        title
        value
      }
    }
  }
`;

export const GET_RETURN_OF_INVESTMENT_STATISTICS = gql`
  query GetReturnOfInvestmentStatistics($carNumber: String!, $untilDate: DateTime) {
    car(number: $carNumber) {
      id
      fullName
      vehicleType
      ...ReturnOnInvestmentCarInfoFragment
      ...ReturnOnInvestmentCarReturnsFragment
      ...ReturnOnInvestmentInterestDialogFragment
      history {
        id
        ...ReturnOnInvestmentCarHistoryFragment
      }
      activeMonths {
        id
        month
        year
        sales
      }
      notes
    }
  }
  ${RETURN_OF_INVESTMENT_CAR_INFO_FRAGMENT}
  ${RETURN_OF_INVESTMENT_CAR_RETURNS_FRAGMENT}
  ${RETURN_OF_INVESTMENT_CAR_HISTORY_FRAGMENT}
  ${RETURN_OF_INVESTMENT_INTEREST_DIALOG_FRAGMENT}
`;

export const GET_RETURN_OF_INVESTMENT_STATISTICS_MONTHS = gql`
  query GetReturnOfInvestmentStatisticsMonths($carId: ID!) {
    car(id: $carId) {
      id
      activeMonths {
        id
        month
        year
        sales
        amortization
        costs {
          id
          motorInsurance
          insurance
          roadTax
          interest
        }
      }
    }
  }
`;

export const GET_RETURN_OF_INVESTMENT_STATISTICS_CONTRACTS = gql`
  query GetReturnOfInvestmentStatisticsContracts($carId: ID!) {
    car(id: $carId) {
      id
      contracts {
        id
        type
        number
        invoice {
          id
          number
        }
        tenant
        rentalStartDate
        rentalEndDate
        rentalPrice
        vat
        feeSum
        status
      }
    }
  }
`;

export const GET_CAR_CHANGELOG = gql`
  query GetCarChangeLog($carId: ID!) {
    car(id: $carId) {
      id
      changelog {
        id
        version
        event
        method
        user
        diff(format: HTML)
        timestamp
      }
    }
  }
`;

export const GET_RETURN_OF_INVESTMENT_STATISTICS_INVOICES = gql`
  query GetReturnOfInvestmentStatisticsInvoices($carId: ID!) {
    car(id: $carId) {
      id
      invoices {
        id
        type
        number
        contract {
          id
          number
        }
        tenant
        rentalStartDate
        rentalEndDate
        rentalPrice
        vat
        feeSum
      }
    }
  }
`;

export const GET_DUPLICATE_CARS = gql`
  query GetDuplicateCars {
    duplicateCars {
      id
      number
    }
  }
`;

export const GET_WRONG_HISTORY_CARS = gql`
  query GetWrongHistoryCars {
    wrongHistoryCars {
      id
      number
      startOfRentingDate
      saleDate
    }
  }
`;

export const GET_CAR_PRICES = gql`
  query GetCarPrices {
    cars {
      id
      number
      fullName
      pricelistPrice
      price
      salePrice
    }
  }
`;

export const GET_PRICELIST = gql`
  query GetPricelist($type: PricelistType) {
    pricelist(type: $type) {
      id
      type
      variant
      validFrom
      validTo
      prices {
        id
        valueFrom
        valueTo
        price
      }
    }
  }
`;
