// @flow
import React from 'react';

export type Props = {
  /**
   * Percent value
   */
  value: number,
  /**
   * Number of decimal places
   */
  decimals?: number
};

export const NumberString = ({ value, decimals = 2 }: Props) => {
  return (
    <>
      {Number(value)
        .toFixed(decimals)
        .replace(/\d(?=(\d{3})+(\.|$))/g, '$& ')
        .replace('.', ',')}
    </>
  );
};
