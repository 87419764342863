// @flow
import React from 'react';
import moment from 'moment';

export type Props = {
  /**
   * Date value
   */
  value: string
};

export const DateTimeString = ({ value }: Props) => {
  return <>{moment(value).format('D.M.Y H:mm')}</>;
};
