// @flow
import React from 'react';
import moment from 'moment';

import { DatePicker } from '@material-ui/pickers';

export type Props = {
  value: moment,
  onChange: (moment) => void,
  className: string
};

export const YearPicker = ({ value, onChange, className }: Props) => {
  const handleDateChange = (date) => {
    if (typeof onChange === 'function') {
      onChange(date);
    }
  };

  return (
    <DatePicker
      format="YYYY"
      label="Rok"
      value={value}
      onChange={handleDateChange}
      openTo="year"
      views={['year']}
      inputVariant="outlined"
      margin="dense"
      size="small"
      variant="inline"
      className={className}
    />
  );
};
