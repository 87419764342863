import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

const setQueryStringValue = (history, key, value, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value });

  history.push({ search: newQsValue });
};

const getQueryStringValue = (key, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  return values[key];
};

export function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const history = useHistory();

  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(history, key, newValue);
    },
    [key, history]
  );

  return [value, onSetValue];
}
