// @flow
import React from 'react';
import moment from 'moment';

import { DatePicker } from '@material-ui/pickers';

export type Props = {
  value: moment,
  onChange: (moment) => void
};

export const MonthPicker = ({ value, onChange }: Props) => {
  const handleDateChange = (date) => {
    if (typeof onChange === 'function') {
      onChange(date);
    }
  };

  return (
    <DatePicker
      format="MM/YYYY"
      label="Mesiac"
      value={value}
      onChange={handleDateChange}
      openTo="month"
      views={['year', 'month']}
      inputVariant="outlined"
      margin="dense"
      size="small"
      variant="inline"
    />
  );
};
