// @flow
import React, { memo, useState, useCallback } from 'react';
import classnames from 'classnames';

import { useMutation } from '@apollo/client';
import { PUT_CAR_ADDITIONAL_COST } from 'api/mutations';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import { Price } from 'components/Price';
import { EditableCell } from 'components/EditableCell';

import { InterestEditDialog } from './InterestEditDialog';
import { AdditionalCostDialog } from './AdditionalCostDialog';

import type { ReturnOnInvestmentCarReturnsFragment } from 'api/__generated__/ReturnOnInvestmentCarReturnsFragment';
import type { ReturnOnInvestmentInterestDialogFragment } from 'api/__generated__/ReturnOnInvestmentInterestDialogFragment';

export type Props = {
  car: ReturnOnInvestmentCarReturnsFragment & ReturnOnInvestmentInterestDialogFragment
};

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 0
  },
  table: {
    marginBottom: theme.spacing(2)
  },
  totalRow: {
    backgroundColor: theme.palette.action.selected,

    '& > *': {
      fontWeight: 'bold'
    }
  },
  cellWithEditIcon: {
    paddingTop: 5,
    paddingBottom: 5,
    '&:hover, &:active, &:focus': {
      '& $icon': {
        display: 'inline-block'
      }
    }
  },
  cellWithEditIconSmall: {
    paddingTop: 3,
    paddingBottom: 3
  },
  icon: {
    display: 'none',
    marginRight: theme.spacing()
  },
  content: {
    display: 'inline-block',
    height: 26,
    lineHeight: '26px',
    verticalAlign: 'middle'
  }
}));

export const CarReturns = memo(({ car }: Props) => {
  const classes = useStyles();

  const [putCost] = useMutation(PUT_CAR_ADDITIONAL_COST);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [aditionalCostDialogOpen, setAditionalCostDialogOpen] = useState(false);

  const openInterestEditDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const closeInterestEditDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const openAdditionalCostDialog = useCallback(() => {
    setAditionalCostDialogOpen(true);
  }, []);

  const closeAdditionalCostDialog = useCallback(() => {
    setAditionalCostDialogOpen(false);
  }, []);

  const handleAdditionalCostChange = useCallback(
    ({ costId, title, value }) => {
      return putCost({
        variables: {
          carId: car.id,
          costId,
          title,
          value: value || 0
        }
      });
    },
    [car.id, putCost]
  );

  if (!car) {
    return null;
  }

  const sales = car.sales || 0;
  const amortization = car.price - car.salePrice;
  const motorInsurance = car.costs.motorInsurance || 0;
  const insurance = car.costs.insurance || 0;
  const roadTax = car.costs.roadTax || 0;
  const interest = car.costs.interest || 0;
  const tires = (car.type && car.type.tirePrice) || 0;
  const totalCosts =
    motorInsurance +
    insurance +
    roadTax +
    interest +
    tires +
    car.additionalCosts.reduce((s, i) => s + i.value, 0);
  const total = sales - totalCosts - amortization;

  return (
    <>
      <CardHeader subheader="Súhrn" className={classes.title} />

      <Table size="small" aria-label="simple table" className={classes.table}>
        <TableBody>
          <TableRow key="amortization">
            <TableCell component="th" scope="row" variant="head">
              Skutočná celková amortizácia
            </TableCell>

            <TableCell align="right">
              <Price value={amortization} isCost={true} />
            </TableCell>
          </TableRow>

          <TableRow key="motorInsurance">
            <TableCell component="th" scope="row" variant="head">
              PZP
            </TableCell>

            <TableCell align="right">
              <Price value={motorInsurance} isCost={true} />
            </TableCell>
          </TableRow>

          <TableRow key="insurance">
            <TableCell component="th" scope="row" variant="head">
              KASKO
            </TableCell>

            <TableCell align="right">
              <Price value={insurance} isCost={true} />
            </TableCell>
          </TableRow>

          <TableRow key="roadTax">
            <TableCell component="th" scope="row" variant="head">
              Daň z motorových vozidiel
            </TableCell>

            <TableCell align="right">
              <Price value={roadTax} isCost={true} />
            </TableCell>
          </TableRow>

          <TableRow key="interest">
            <TableCell component="th" scope="row" variant="head">
              Úrok
            </TableCell>

            <TableCell align="right" className={classes.cellWithEditIcon}>
              <IconButton size="small" onClick={openInterestEditDialog} className={classes.icon}>
                <EditIcon fontSize="small" />
              </IconButton>

              <div className={classes.content}>
                <Price value={interest} isCost={true} />
              </div>
            </TableCell>
          </TableRow>

          <TableRow key="tires">
            <TableCell component="th" scope="row" variant="head">
              Zimné gumy 1/2
            </TableCell>

            <TableCell align="right">
              <Price value={tires} isCost={true} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <CardHeader
        subheader="Ďalšie náklady"
        className={classes.title}
        action={
          <IconButton size="small" aria-label="add" onClick={openAdditionalCostDialog}>
            <AddIcon />
          </IconButton>
        }
      />

      <Table size="small" aria-label="simple table" className={classes.table}>
        <TableBody>
          {car.additionalCosts.length > 0 ? (
            car.additionalCosts.map((cost) => (
              <TableRow key={cost.id}>
                <TableCell component="th" scope="row" variant="head">
                  {cost.title}
                </TableCell>

                <EditableCell
                  align="right"
                  value={cost.value || 0}
                  onChange={handleAdditionalCostChange}
                  textFieldProps={{
                    size: 'small',
                    InputProps: {
                      endAdornment: <InputAdornment position="end">&euro;</InputAdornment>
                    }
                  }}
                  data={{
                    costId: cost.id,
                    title: cost.title
                  }}
                  className={classnames(classes.cellWithEditIcon, classes.cellWithEditIconSmall)}
                >
                  <Price isCost value={cost.value || 0} />
                </EditableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="body2" color="textSecondary">
                  Žiadne dodatočné náklady
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <CardHeader subheader="Spolu" className={classes.title} />

      <Table size="small" aria-label="simple table" className={classes.table}>
        <TableBody>
          <TableRow key="sales">
            <TableCell component="th" scope="row" variant="head">
              Tržby z prenájmu
            </TableCell>

            <TableCell align="right">
              {sales && (
                <>
                  <Typography variant="caption" color="textSecondary" title="mesačne">
                    (<Price value={sales / (car.activeMonthsTotal || 1)} />)
                  </Typography>{' '}
                </>
              )}
              <Price value={sales} />
            </TableCell>
          </TableRow>

          <TableRow key="totalCosts">
            <TableCell component="th" scope="row" variant="head">
              Celkové náklady
            </TableCell>

            <TableCell align="right">
              {totalCosts && (
                <>
                  <Typography variant="caption" color="textSecondary" title="mesačne">
                    (<Price value={totalCosts / (car.activeMonthsTotal || 1)} />)
                  </Typography>{' '}
                </>
              )}
              <Price isCost value={totalCosts} />
            </TableCell>
          </TableRow>

          <TableRow key="total" className={classes.totalRow}>
            <TableCell component="th" scope="row" variant="head">
              Marža vozidla celkom
            </TableCell>

            <TableCell align="right">
              <Price greenPositive value={total} />
            </TableCell>
          </TableRow>

          <TableRow key="totalMonth" className={classes.totalRow}>
            <TableCell component="th" scope="row" variant="head">
              Marža vozidla mesačná
            </TableCell>

            <TableCell align="right">
              <Price greenPositive value={total / (car.activeMonthsTotal || 1)} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <InterestEditDialog car={car} open={dialogOpen} onClose={closeInterestEditDialog} />

      <AdditionalCostDialog
        car={car}
        open={aditionalCostDialogOpen}
        onClose={closeAdditionalCostDialog}
      />
    </>
  );
});
